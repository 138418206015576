import "./portfolio.css";

import IMG1 from "../../assets/B2B.PNG";
import IMG2 from "../../assets/MandSon.PNG";
import IMG3 from "../../assets/comingSoon.webp";
import IMG4 from "../../assets/RadiologyWeb.PNG";
import IMG5 from "../../assets/Potsave.png";
import IMG6 from "../../assets/Health.png";
import React from "react";

//Portfolio function
const Portfolio = () => {
  const soloProjects = [
    {
      id: 1,
      title: "Scheduling App ",
      img: IMG1,
      description:
        "Online scheduling app for a radiology clinic that allows partnering hopstials to schedule patient studies online",
      technologies: "Express | jQuery | Nginx | MySQL ",

      message: "Check back later for the live DEMO!"
    },
    {
      id: 2,
      title: "Inventory App",
      img: IMG2,
      description:
        "Simple responsive inventory application that uses a scangun to automatically add pieces. For a small glassware company.",
      technologies: "Python | Flask | Jinja | PostgreSQL",
      link: "https://project2.epadilla-portfolio.com/",
    },
    {
      id: 3,
      title: "Radiology Web",
      img: IMG4,
      description:
      "A full-stack platform to easily view, schedule, and securely share medical records with your primary physician, clinics, and hospitals.",
      technologies: "Node.js| Python | React | Nginx | MySQL ",

      message: "Check back later for the live DEMO!"
    },
    {
      id: 4,
      title: "More to Come!",
      img: IMG3,
      description:
        "Working on publishing my other projects, check back soon!",
    },
    
  ];

  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {soloProjects.map((pro) => (
          <article className="portfolio__item" key={pro.id}>
            <div className="portfolio__item-image">
              <img src={pro.img} alt={pro.title} />
            </div>
            <div className="portfolio__item-content">
              <h3>{pro.title}</h3>
              <p>{pro.description}</p>
              <p>{pro.technologies}</p>
              {pro.message && <p>{pro.message}</p>}
            </div>
            <div className="portfolio__item-cta">
              {pro.link && (
                <a
                  href={pro.link}
                  target="_blank"
                  className="btn btn-primary"
                  rel="noreferrer"
                >
                  Live Demo
                </a>
              )}
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
